<template>
  <b-card>
    <div class="d-flex flex-wrap justify-content-between">
      <span class="font-medium-2 font-weight-bolder">Discount codes {{ partner.discount.value_string }}</span>

      <b-button
        variant="outline-primary"
        class="btn-icon m-50"
        @click="$router.push({ name: 'discount-detail', params: { id: partner.discount.id } })"
      >
        <feather-icon icon="EyeIcon" /> Show discount
      </b-button>
    </div>
    <hr>
    <b-list-group class="mt-1">
      <b-list-group-item
        v-for="(item, key) in partner.discount.codes"
        :key="key"
        class="d-flex justify-content-between"
      >
        <span class="m-50"><strong>{{ item.value_string }} | {{ item.code }}</strong></span>
        <span class="font-medium-1">
          <b-badge
            variant="light-primary"
            class="m-50 float-right"
          >Usage count: {{ item.usage_count }}</b-badge>
          <b-badge
            variant="light-info"
            class="m-50 float-right"
          > Max count: {{ item.max_count }}</b-badge>
        </span>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>
<script>
import {
  BCard, BListGroup, BListGroupItem, BBadge, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BListGroupItem,
    BListGroup,
    BCard,
    BBadge,
  },
  props: {
    partner: Object,
  },
}
</script>
