<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <div class="d-flex justify-content-start">
          <b-avatar
            v-if="!isMobile"
            variant="light-primary"
            size="104px"
            rounded
          >
            <b-img
              v-if="profileImage"
              :src="profileImage"
            />
            <UserIcon v-else />
          </b-avatar>
          <div class="d-flex flex-column ml-1">
            <div class="mb-1 d-flex flex-wrap">
              <div>
                <h4 class="mb-0">
                  <b-link :to="{ name: 'user-detail', params: { id: partner.user.id } }">
                    {{ partner.user.full_name }}
                  </b-link>
                </h4>
                <span class="card-text">{{ partner.user.email }}</span>
              </div>
              <tags-list
                :tags="partner.user.tags"
                class="m-50"
                :show-hidden-by-toggle="false"
              />
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                variant="outline-primary"
                @click="$emit('edit')"
              >
                <feather-icon
                  icon="EditIcon"
                />
                Edit
              </b-button>
            </div>
          </div>

        </div>

        <div class="d-flex align-items-center mt-2 flex-wrap">
          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="CreditCardIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ partnerData.orders_count }}
              </h5>
              <small>Total number of purchases<br>(registered users)</small>
            </div>
          </div>

          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="UserIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ partnerData.users_count }}
              </h5>
              <small>Number of registered</small>
            </div>
          </div>

          <div class="d-flex align-items-center mr-2">
            <b-avatar
              variant="light-success"
              rounded
            >
              <feather-icon
                icon="ChartIcon"
                size="18"
              />
            </b-avatar>
            <div class="ml-1">
              <h5 class="mb-0">
                {{ formatCurrency(partnerData.orders_sum/partnerData.users_count, 'usd') }}
              </h5>
              <small>Spend per registered user</small>
            </div>
          </div>

        </div>
      </b-col>

      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th>
              <feather-icon
                icon="GlobeIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Country</span>
            </th>
            <td>
              {{ partner.user.country.name }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="KeyIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Affiliate key</span>
            </th>
            <td>
              {{ partner.aff_custom_key }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="KeyIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Referral key</span>
            </th>
            <td>
              {{ partner.referral_custom_key }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="UserPlusIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Registered</span>
            </th>
            <td>
              {{ formatDateTime(partner.user.createdAt) }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="Edit2Icon"
                class="mr-75"
              />
              <span class="font-weight-bold">Signed up as affiliate</span>
            </th>
            <td>
              {{ formatDateTime(partner.createdAt) }}
            </td>
          </tr>
          <tr>
            <td
              colspan="2"
            >
              <hr>
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PercentIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Profit</span>
            </th>
            <td
              class="cursor-pointer"
            >
              {{ partner.profit }}%
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PercentIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">First profit</span>
            </th>
            <td
              class="cursor-pointer"
            >
              {{ partner.first_profit }}%
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Payout after</span>
            </th>
            <td
              class="cursor-pointer"
            >
              {{ partner.payout_after }} Days
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">First profit expire after</span>
            </th>
            <td
              class="cursor-pointer"
            >
              {{ partner.first_profit_expire_after }} Days
            </td>
          </tr>
          <tr v-if="partner.giveaway_after > 0">
            <th>
              <feather-icon
                icon="CalendarIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Giveaway after</span>
            </th>
            <td
              class="cursor-pointer"
            >
              {{ partner.giveaway_after }} Days
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="DollarSignIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Min payout</span>
            </th>
            <td
              class="cursor-pointer"
            >
              {{ formatCurrency(partner.min_payout, 'USD') }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="KeyIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Max discount codes count</span>
            </th>
            <td
              class="cursor-pointer"
            >
              {{ partner.max_discount_codes }} Days
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BLink, BImg,
} from 'bootstrap-vue'
import { UserIcon } from 'vue-feather-icons'
import { get } from 'vuex-pathify'
import TagsList from '@/components/page/TagsList.vue'
import { formatDateTime, formatCurrency } from '@/plugins/formaters'

export default {
  components: {
    BImg,
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    UserIcon,
    BLink,
    TagsList,
  },
  props: {
    partnerData: Object,
  },
  data() {
    return {
      showInfo: false,
    }
  },
  computed: {
    partner() {
      return this.partnerData.partner
    },
    profileImage() {
      return this.partnerData.partner.user.profile_image ? `${process.env.VUE_APP_API_URL}users/${this.partnerData.partner.user.id}/avatar/${this.partnerData.partner.user.profile_image}` : null
    },
    ...get('app', ['isMobile']),
  },
  methods: {
    formatDateTime,
    formatCurrency,
  },
}
</script>
