<template>
  <b-overlay :show="loading">
    <b-row>
      <b-col
        v-for="(item, key) in payoutsCategories"
        :key="key"
        lg="4"
        md="4"
        sm="12"
      >
        <payout-card
          :title="item.label"
          :variant="item.variant"
          :order-count="item.count"
          :profit-amount="item.amount"
          :show-btn="item.showBtn"
          :emit="item.emit"
          @set-paid="onSetAsPaid"
        />
      </b-col>
    </b-row>
  </b-overlay>
</template>
<script>
import { BRow, BCol, BOverlay } from 'bootstrap-vue'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PayoutCard from '@/views/partner/detail/components/PayoutCard.vue'
import { formatCurrency } from '@/plugins/formaters'
import AffiliateAPI from '@/api/affiliate'

export default {
  components: {
    PayoutCard, BRow, BCol, BOverlay,
  },
  props: {
    payouts: Array,
    partnerId: String,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    payoutsCategories() {
      const payouts = {
        paid: {
          label: 'Paid',
          variant: 'light-success',
          count: 0,
          amount: this.formatCurrency(0, 'USD'),
          showBtn: false,
          emit: null,
        },
        waiting: {
          label: 'Waiting for payout',
          variant: 'light-warning',
          count: 0,
          amount: this.formatCurrency(0, 'USD'),
          showBtn: true,
          emit: 'set-paid',
        },
        newPayouts: {
          label: 'New',
          variant: 'light-primary',
          count: 0,
          amount: this.formatCurrency(0, 'USD'),
          showBtn: false,
          emit: null,
        },
      }

      this.payouts.forEach(item => {
        if (Number(item.is_paid) === 1) {
          payouts.paid.count = item.orders_count
          payouts.paid.amount = this.formatCurrency(item.profit, 'USD')
        } else if (Number(item.is_paid) === 0 && Number(item.is_asking) === 1) {
          payouts.waiting.count = item.orders_count
          payouts.waiting.amount = this.formatCurrency(item.profit, 'USD')
        } else if (Number(item.is_paid) === 0 && Number(item.is_asking) === 0) {
          payouts.newPayouts.count = item.orders_count
          payouts.newPayouts.amount = this.formatCurrency(item.profit, 'USD')
        }
      })

      return payouts
    },
  },
  methods: {
    formatCurrency,
    onSetAsPaid() {
      Swal.fire({
        title: 'Are you sure?',
        text: 'All requested payouts will be set as paid',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, set as paid',
      }).then(result => {
        if (result.isConfirmed) {
          this.loading = true
          AffiliateAPI.setPartnerPayoutsAsPaid(this.partnerId).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Payouts were successfully set as paid.',
                icon: 'WarningIcon',
                variant: 'success',
              },
            })
            this.$emit('reload')
          }).finally(() => {
            this.loading = false
          })
        }
      })
    },
  },
}
</script>
