<template>
  <div>
    <b-row class="mb-2">
      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>ID: </strong>{{ row.item.id }}
      </b-col>
      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Full Name: </strong>{{ row.item.full_name }}
      </b-col>
      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Email: </strong>{{ row.item.email }}
      </b-col>
      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Phone: </strong>{{ row.item.phone }}
      </b-col>
      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Address: </strong>{{ `${row.item.address}, ${row.item.zipcode} ${row.item.city} ${row.item.state}` }}
      </b-col>
      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Country: </strong>{{ row.item.country.name }}
      </b-col>
      <b-col
        lg="4"
        class="mb-1"
      >
        <strong>Registered at: </strong>{{ formatDateTime(row.item.createdAt) }}
      </b-col>
    </b-row>

    <b-button
      size="sm"
      variant="outline-secondary"
      @click="row.toggleDetails"
    >
      Hide Details
    </b-button>
  </div>
</template>

<script>
import { BCol, BRow, BButton } from 'bootstrap-vue'
import { formatDateTime } from '@/plugins/formaters'

export default {
  components: {
    BRow,
    BCol,
    BButton,
  },
  props: {
    row: Object,
  },
  methods: {
    formatDateTime,
  },
}
</script>
