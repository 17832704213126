<template>
  <div>
    <gql-table
      ref="partnerWithdrawalTable"
      :query="query"
      :fields="columns"
      query-type="partnerWithdrawals"
      sort-by="createdAt"
      :sort-desc="true"
      :actions="actions"
      :title="tableTitle"
      @edit="onEdit"
      @confirm="onConfirm"
      @stateInvestigationContacted="onInvestigationContacted"
      @stateInvestigationDeclined="onInvestigationDeclined"
      @delete="onRemove"
    >
      <template #cell(partnerUser)="data">
        <b-row>
          <b-col>
            <b-link :to="{ name: 'user-detail', params: { id: data.item.partner.user.id } }">
              {{ data.item.partner.user.firstName }} {{ data.item.partner.user.lastName }}
              ({{ data.item.partner.user.country.name }})
              <br>
              <small>{{ data.item.partner.user.email }}</small>
            </b-link>
          </b-col>
          <b-col>
            <tags-list
              :tags="data.item.partner.user.tags"
              :extras-tags="getUserExtraTags(data.item.partner.user)"
              extras-variant="light-success"
            />
          </b-col>
        </b-row>
      </template>

      <template #cell(amount)="data">
        {{ formatCurrency(data.item.amount, 'USD') }}
      </template>

      <template #cell(state)="data">
        <b-badge
          :variant="`light-${withdrawalStateVariants[data.item.state]}`"
        >
          {{ data.item.state }}
        </b-badge>
      </template>

      <template #cell(createdAt)="data">
        {{ formatDateTime(data.item.createdAt) }}
      </template>

      <template #cell(confirmed_at)="data">
        {{ formatDateTime(data.item.confirmed_at) }}
      </template>

      <template #cell(stateAction)="data">
        <b-button
          v-if="!data.item.confirmed_at"
          v-b-tooltip.hover.left="'Approve withdrawal to payout'"
          variant="outline-success"
          class="btn-icon m-50"
          @click="onChangeState(data.item, 'approved by support')"
        >
          <feather-icon icon="UserCheckIcon" />
        </b-button>
        <b-button
          v-if="!data.item.confirmed_at"
          v-b-tooltip.hover.left="'Change state to INVESTIGATION'"
          variant="outline-warning"
          class="btn-icon m-50"
          @click="onChangeState(data.item, 'investigation')"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </template>
    </gql-table>
    <b-modal
      id="withdrawalModal"
      :title="title"
      :no-close-on-backdrop="true"
      hide-footer
    >
      <partner-withdrawal-form
        :automatic-reload="false"
        :withdrawal="withdrawalEdit"
        module-name="partnerWithdrawalList"
        @submit="onEditSubmit"
      />
    </b-modal>
  </div>
</template>
<script>
import {
  BBadge,
  BButton, BCol, BLink, BModal, BRow, VBTooltip,
} from 'bootstrap-vue'
import Swal from 'sweetalert2'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'
import TagsList from '@/components/page/TagsList.vue'

import { formatDateTime, formatCurrency } from '@/plugins/formaters'
import { getUserExtraTags } from '@/plugins/userUtils'
import { getAccountExtraTags } from '@/plugins/accountUtils'
import { STATE_VARIANTS } from '@/enums/withdrawals'
import PartnerAPI from '@/api/partner'
import PartnerWithdrawalForm from '@/components/forms/partner/PartnerWithdrawalForm.vue'

const actions = [
  {
    text: 'Edit',
    icon: 'EditIcon',
    emit: 'edit',
  },
  {
    text: 'Confirm',
    icon: 'CheckCircleIcon',
    emit: 'confirm',
  },
  {
    text: 'Investigation - CONTACTED',
    icon: 'XIcon',
    emit: 'stateInvestigationContacted',
  },
  {
    text: 'Investigation - DECLINED',
    icon: 'XIcon',
    emit: 'stateInvestigationDeclined',
  },
  {
    text: 'Remove withdrawal',
    icon: 'TrashIcon',
    emit: 'delete',
  },
]

export default {
  components: {
    PartnerWithdrawalForm,
    BBadge,
    BModal,
    BRow,
    BButton,
    TagsList,
    BCol,
    BLink,
    GqlTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    tableTitle: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      withdrawalStateVariants: STATE_VARIANTS,
      title: 'Create withdrawal',
      withdrawalEdit: null,
      actions,
      query: [
        'id',
        'amount',
        'confirmed_at',
        'createdAt',
        'state',
        'platform',
        {
          partner: {
            fields: [
              'aff_custom_key',
              {
                user: {
                  fields: [
                    'firstName',
                    'lastName',
                    'id',
                    'email',
                    'kyc_at',
                    'purchase_disabled',
                    { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } },
                    { country: { fields: ['id', 'name'] } },
                  ],
                },
              },
            ],
          },
        },
      ],
    }
  },
  methods: {
    formatDateTime,
    formatCurrency,
    getUserExtraTags,
    getAccountExtraTags,
    onChangeState(item, state) {
      PartnerAPI.changeWithdrawalState(item.id, state).then(() => {
        this.$refs.partnerWithdrawalTable.reloadAll(true)
      })
    },
    onInvestigationContacted(item) {
      PartnerAPI.changeWithdrawalState(item.id, 'investigation - contacted').then(() => {
        this.$refs.partnerWithdrawalTable.reloadAll(true)
      })
    },
    onInvestigationDeclined(item) {
      PartnerAPI.changeWithdrawalState(item.id, 'investigation - declined').then(() => {
        this.$refs.partnerWithdrawalTable.reloadAll(true)
      })
    },
    onEdit(item) {
      this.title = 'Edit withdrawal'
      this.withdrawalEdit = item
      this.$bvModal.show('withdrawalModal')
    },
    onEditSubmit() {
      this.$bvModal.hide('withdrawalModal')
      this.withdrawalEdit = null
      this.$refs.partnerWithdrawalTable.reloadAll(true)
    },
    onCreateWithdrawal() {
      this.title = 'Create withdrawal'
      this.withdrawalEdit = null
      this.$bvModal.show('withdrawalModal')
    },
    onConfirm(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Withdrawal will be approved!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, confirm',
      }).then(result => {
        if (result.isConfirmed) {
          PartnerAPI.confirmWithdrawal(item.id).then(() => {
            this.$refs.partnerWithdrawalTable.reloadAll(true)
          }).catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Filed',
                icon: 'XCircleIcon',
                text: err.data.detail,
                variant: 'danger',
              },
            })
          })
        }
      })
    },
    onRemove(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Withdrawal will be remove',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, remove',
      }).then(result => {
        if (result.isConfirmed) {
          PartnerAPI.removeWithdrawal(item.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Withdrawal was removed',
                icon: 'TrashIcon',
                text: '',
                variant: 'success',
              },
            })
            this.$refs.partnerWithdrawalTable.reloadAll(true)
          })
        }
      })
    },
  },
}
</script>
