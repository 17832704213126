<template>
  <entity-form
    :id="withdrawal ? withdrawal.id : null"
    :module-name="moduleName"
    :model="form"
    :automatic-reload="automaticReload"
    :before-submit="beforeSubmit"
    @submit="onSubmit"
  >
    <b-form-group label="Amount">
      <b-form-input
        v-model.number="form.amount"
        min="0"
      />
    </b-form-group>

    <b-form-group label="Platform">
      <b-form-select
        v-model="form.platform"
        :disabled="withdrawal.state === 'completed'"
        :options="['plane', 'rise', 'crypto']"
      />
    </b-form-group>
  </entity-form>
</template>
<script>
import Ripple from 'vue-ripple-directive'
import {
  BFormGroup, BFormInput, BFormSelect,
} from 'bootstrap-vue'
import EntityForm from '@/components/ui/forms/EntityForm.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BFormSelect,
    EntityForm,
    BFormGroup,
    BFormInput,
  },
  props: {
    withdrawal: {
      required: false,
      type: Object,
    },
    moduleName: String,
    automaticReload: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      form: {
        amount: this.withdrawal ? this.withdrawal.amount : 0,
        platform: this.withdrawal ? this.withdrawal.platform : null,
      },
    }
  },
  methods: {
    beforeSubmit() {
      return true
    },
    onSubmit(data) {
      this.$emit('submit', data)
    },
  },
}
</script>
