<template>
  <gql-table
    ref="registeredUsersTable"
    :fields="fields"
    :query="query"
    query-type="users"
    sort-by="createdAt"
    :sort-desc="true"
    title="Registered users"
  >
    <template #cell(show_details)="row">
      <show-details :row="row" />
    </template>

    <template #row-details="row">
      <row-detail :row="row" />
    </template>

    <template #cell(full_name)="data">
      <b-link :to="{name: 'user-detail', params: {id: data.item.id}}">
        {{ data.item.firstName }} {{ data.item.lastName }}
      </b-link>
    </template>

    <template #cell(email)="data">
      {{ data.item.email }}
    </template>

    <template #cell(tags)="data">
      <tags-list
        :tags="data.item.tags"
        :extras-tags="getExtraTags(data.item)"
        extras-variant="light-success"
      />
    </template>

    <template #cell(createdAt)="data">
      {{ formatDateTime(data.item.createdAt) }}
    </template>
  </gql-table>
</template>

<script>
import {
  BLink,
} from 'bootstrap-vue'
import ShowDetails from '@/components/ui/tables/ShowDetails.vue'
import RowDetail from '@/components/page/users/components/RowDetail.vue'
import { formatDateTime } from '@/plugins/formaters'
import TagsList from '@/components/page/TagsList.vue'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'

const query = [
  'firstName',
  'lastName',
  'id',
  'email',
  'kyc_at',
  'purchase_disabled',
  'profile_image',
  'createdAt',
  'phone',
  'address',
  'city',
  'zipcode',
  'state',
  { country: { fields: ['name', 'code2'] } },
  { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } },
]

export default {
  components: {
    ShowDetails,
    RowDetail,
    BLink,
    TagsList,
    GqlTable,
  },
  props: {
    partnerId: String,
  },
  data() {
    return {
      // fields,
      query,
    }
  },
  computed: {
    fields() {
      return [
        {
          name: 'full_name',
          label: 'Full name',
        },
        {
          name: 'firstName', label: 'First name', filterable: 'like', visible: false,
        },
        {
          name: 'lastName', label: 'Last name', filterable: 'like', visible: false,
        },
        {
          name: 'email',
          label: 'Email',
          filterable: 'like',
          sortable: true,
        },
        {
          name: 'tags',
          label: 'Tag',
          filterable: {
            type: 'multientity', queryType: 'tags', value: 'id', label: 'name', search: 'name',
          },
        },
        {
          name: 'createdAt',
          label: 'Created at',
          sortable: true,
          filterable: 'daterange',
        },
        {
          name: 'kyc_at',
          label: 'KYC at',
          filterable: 'daterange',
          visible: false,
        },
        {
          name: 'country',
          label: 'Country',
          filterable: {
            type: 'multientity', queryType: 'countries', value: 'id', label: 'name', search: 'name',
          },
          visible: false,
        },
        {
          name: 'address',
          label: 'Address',
          filterable: 'like',
          visible: false,
        },
        {
          name: 'partner',
          label: 'partner',
          filterable: { type: 'eq', visible: false },
          default: this.partnerId,
          visible: false,
        },
      ]
    },
  },
  methods: {
    formatDateTime,
    getExtraTags(item) {
      const tags = []
      if (item.kyc_at && item.verification_data !== null) tags.push(`Verified ${formatDateTime(item.kyc_at)}`)
      if (item.kyc_at && item.verification_data === null) tags.push(`Verified by support ${formatDateTime(item.kyc_at)}`)
      if (item.purchase_disabled) tags.push({ name: 'Purchase disabled', variant: 'light-danger' })
      return tags
    },
  },
}
</script>
