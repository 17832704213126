<template>
  <b-card>
    <b-badge
      :variant="variant"
      class="p-50 my-auto"
    >
      <span class="font-medium-2">{{ title }}</span>
    </b-badge>
    <b-button
      v-if="showBtn"
      class="mx-25 my-auto float-right"
      variant="outline-warning"
      size="md"
      @click="onClickBtn"
    >
      Set as paid
    </b-button>
    <hr>
    <div class="d-flex flex-wrap justify-content-end">
      <span class="font-medium-1 mx-50 p-75 my-auto"><strong>Orders count: <b-badge variant="light-info">{{ orderCount }}</b-badge></strong></span>
      <span class="font-medium-1 mx-50 p-75 my-auto"><strong>Profit amount: <b-badge variant="light-info">{{ profitAmount }}</b-badge></strong></span>
    </div>
  </b-card>
</template>
<script>
import { BBadge, BButton, BCard } from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BCard,
    BBadge,
  },
  props: {
    title: String,
    variant: String,
    orderCount: Number,
    profitAmount: String,
    emit: String,
    showBtn: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClickBtn() {
      if (!this.emit) return
      this.$emit(this.emit)
    },
  },
}
</script>
