import axiosInstance from '@/plugins/axios'

export default {
  patchAffiliateAsPaid({ months, partnerId }) {
    const url = `admin/affiliate/${partnerId}/paid`

    return axiosInstance.post(url, { months })
      .then(response => response.data)
  },
  sendCustomAffMail(mail) {
    const url = 'admin/affiliate/email'

    return axiosInstance.post(url, mail)
  },

  setPartnerPayoutsAsPaid(partnerId) {
    const url = `admin/partner/${partnerId}/paid`

    return axiosInstance.get(url)
      .then(response => response.data)
  },
}
