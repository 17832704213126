<template>
  <b-card>
    <span class="font-medium-2 font-weight-bolder">Giveaway codes</span>
    <hr>
    <b-list-group
      v-if="codes.length > 0"
      class="mt-1"
    >
      <b-list-group-item
        v-for="(item, key) in codes"
        :key="key"
        class="d-flex justify-content-between"
      >
        <span class="m-50"><strong>{{ item.code }}</strong></span>
        <span class="font-medium-1">
          <b-badge
            variant="light-primary"
            class="m-50 float-right"
          >Usage count: {{ item.usage_count }}</b-badge>
          <b-badge
            variant="light-info"
            class="m-50 float-right"
          > Max count: {{ item.max_count }}</b-badge>
        </span>
      </b-list-group-item>
    </b-list-group>
    <span v-else>
      Partner has no giveaway codes
    </span>
    <b-row
      v-if="partner.discount_codes.length > 3"
      class="text-center"
      align-v="center"
    >
      <b-col>
        <b-button
          variant="outline-primary"
          class="btn-icon mb-auto mt-1"
          @click="showAll = !showAll"
        >
          <feather-icon
            v-if="!showAll"
            icon="PlusIcon"
          />
          <feather-icon
            v-else
            icon="MinusIcon"
          />
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import {
  BCard, BListGroup, BListGroupItem, BBadge, BButton, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BRow,
    BListGroupItem,
    BListGroup,
    BCard,
    BBadge,
    BButton,
  },
  props: {
    partner: Object,
  },
  data() {
    return {
      showAll: false,
    }
  },
  computed: {
    codes() {
      return this.showAll ? this.partner.discount_codes : this.partner.discount_codes.slice(0, 3)
    },
  },
}
</script>
