<template>
  <gql-table
    ref="partnerPayoutsGqlTable"
    :fields="fields"
    :query="query"
    title="Payouts"
    query-type="partnerPayouts"
    sort-by="createdAt"
    :sort-desc="true"
  >
    <template #cell(order)="data">
      <b-link :to="{name: 'order-detail', params: {id: data.item.order.order_number}}">
        {{ data.item.order.order_number }}
      </b-link>
    </template>

    <template #cell(item_description)="data">
      {{ data.item.order.item_description }}
    </template>

    <template #cell(orderUser)="data">
      <b-row>
        <b-col>
          <b-link :to="{ name: 'partner-detail', params: { id: data.item.order.user.id } }">
            {{ data.item.order.user.firstName }} {{ data.item.order.user.lastName }}
            <br>
            <small>{{ data.item.order.user.email }}</small>
          </b-link>
        </b-col>
        <b-col>
          <tags-list
            :tags="data.item.order.user.tags"
            :extras-tags="getUserExtraTags(data.item.order.user)"
            extras-variant="light-success"
          />
        </b-col>
      </b-row>
    </template>

    <template #cell(price)="data">
      {{ formatCurrency(data.item.order.price, 'USD') }}
    </template>

    <template #cell(original_price)="data">
      {{ formatCurrency(data.item.order.original_price, 'USD') }}
    </template>

    <template #cell(payment_datetime)="data">
      <span v-if="data.item.order.payment_datetime">{{ formatDateTime(data.item.order.payment_datetime) }}</span>
      <span v-if="data.item.order.processor">({{ data.item.order.processor }})</span>
    </template>

    <template #cell(partner_fee)="data">
      <b-badge variant="light-primary">
        {{ formatCurrency(data.item.profit_amount, 'USD') }} ({{ data.item.profit }}%)
      </b-badge>
    </template>

    <template #cell(paid_at)="data">
      <b-badge
        v-if="data.item.paid_at"
        variant="light-success"
      >
        {{ formatDateTime(data.item.paid_at) }}
      </b-badge>
    </template>

    <template #cell(ask_for_payout_at)="data">
      <b-badge
        v-if="data.item.ask_for_payout_at"
        variant="light-warning"
      >
        {{ formatDateTime(data.item.ask_for_payout_at) }}
      </b-badge>
    </template>

    <template #cell(declined_at)="data">
      <b-badge
        v-if="data.item.declined_at"
        variant="light-danger"
      >
        {{ formatDateTime(data.item.declined_at) }}
      </b-badge>
    </template>

    <template #cell(type)="data">
      <b-badge
        :variant="payoutTypesVariants[data.item.type]"
      >
        {{ data.item.type }}
      </b-badge>
    </template>
  </gql-table>
</template>
<script>
import {
  BCol, BLink, BRow, BBadge,
} from 'bootstrap-vue'
import GqlTable from '@/components/ui/gqltables/GqlTable.vue'
import TagsList from '@/components/page/TagsList.vue'
import { getUserExtraTags } from '@/plugins/userUtils'
import { formatCurrency, formatDateTime } from '@/plugins/formaters'
import { PARTNER_PAYOUT_TYPE_VARIANTS } from '@/enums/affiliate'

const query = [
  'id',
  'paid_at',
  'declined_at',
  'ask_for_payout_at',
  'profit',
  'profit_amount',
  'type',
  'createdAt',
  {
    order: {
      fields: [
        'id',
        'order_number',
        'price',
        'original_price',
        'item_description',
        'payment_datetime',
        'processor',
        'state',
        {
          user: {
            fields: [
              'firstName',
              'lastName',
              'id',
              'email',
              'kyc_at',
              'createdAt',
              { country: { fields: ['name', 'code2'] } },
              { tags: { fields: [{ tag: { fields: ['name', 'visible', 'color', 'description'] } }, 'createdAt'] } }],
          },
        },
      ],
    },
  },
]

export default {
  components: {
    TagsList, BLink, BCol, BRow, GqlTable, BBadge,
  },
  props: {
    partner: String,
  },
  data() {
    return {
      query,
      payoutTypesVariants: PARTNER_PAYOUT_TYPE_VARIANTS,
      fields: [
        {
          name: 'order',
          label: 'Order number',
          filterable: {
            type: 'entity', queryType: 'orders', value: 'id', label: 'order_number', search: 'order_number',
          },
        },
        {
          name: 'item_description',
          label: 'product',
        },
        {
          name: 'orderUser',
          label: 'User',
          filterable: {
            type: 'entity', queryType: 'users', value: 'id', label: 'email', search: 'email',
          },
        },
        {
          name: 'price',
          label: 'Price',
        },
        {
          name: 'original_price',
          label: 'Original price',
        },
        {
          name: 'payment_datetime',
          label: 'Paid at',
        },
        {
          name: 'partner_fee',
          label: 'Partner fee',
        },
        {
          name: 'paid_at',
          label: 'Partner fee paid at',
        },
        {
          name: 'ask_for_payout_at',
          label: 'Ask for payout at',
        },
        {
          name: 'declined_at',
          label: 'Partner fee declined at',
        },
        {
          name: 'type',
          label: 'Aff type',
        },
        {
          name: 'partner',
          label: 'partner',
          filterable: {
            type: 'entity', queryType: 'eq', visible: false,
          },
          visible: false,
          default: this.partner,
        },
      ],
    }
  },
  methods: {
    getUserExtraTags,
    formatDateTime,
    formatCurrency,
  },
}
</script>
