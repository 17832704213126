<template>
  <b-overlay :show="loading">
    <div v-if="partnerData">
      <user-detail
        :partner-data="partnerData"
        @edit="onEdit"
      />
      <payouts
        :payouts="partnerData.payouts"
        :partner-id="partnerData.partner.id"
        @reload="loadPartner()"
      />

      <b-row>
        <b-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
        >
          <discount-codes :partner="partnerData.partner" />
        </b-col>
        <b-col
          xl="6"
          lg="6"
          md="6"
          sm="12"
        >
          <giveaway-codes :partner="partnerData.partner" />
        </b-col>
      </b-row>

      <payouts-table :partner="partnerData.partner.id" />

      <user-partner-withdrawals-table
        class="mt-2"
        :user="partnerData.partner.user"
      />

      <registered-users-gql
        :partner-id="partnerData.partner.id"
        class="mt-2"
      />

      <b-modal
        id="partnerModal"
        title="Partner edit"
        :no-close-on-backdrop="true"
        hide-footer
      >
        <partner-form
          :automatic-reload="false"
          :partner="partnerData.partner"
          module-name="partnerList"
          @submit="onEditSubmit"
        />
      </b-modal>
    </div>
  </b-overlay>
</template>
<script>
import {
  BOverlay, BRow, BCol,
} from 'bootstrap-vue'
import PartnerApi from '@/api/partner'
import UserDetail from '@/views/partner/detail/components/UserDetail.vue'
import Payouts from '@/views/partner/detail/components/Payouts.vue'
import PayoutsTable from '@/views/partner/detail/components/PayoutsTable.vue'
import DiscountCodes from '@/views/partner/detail/components/DiscountCodes.vue'
import GiveawayCodes from '@/views/partner/detail/components/GiveawayCodes.vue'
import PartnerForm from '@/components/forms/partner/PartnerForm.vue'
import UserPartnerWithdrawalsTable from '@/components/entity/partner-withdrawal-gql/UserPartnerWithdrawalsTable.vue'
import RegisteredUsersGql from '@/views/partner/detail/components/RegisteredUsersGql.vue'

export default {
  components: {
    RegisteredUsersGql,
    UserPartnerWithdrawalsTable,
    PartnerForm,
    GiveawayCodes,
    DiscountCodes,
    PayoutsTable,
    Payouts,
    UserDetail,
    BOverlay,
    BRow,
    BCol,
  },
  data() {
    return {
      partnerData: null,
      loading: false,
    }
  },
  computed: {
    partnerId() {
      return this.$route.params.id
    },
  },
  beforeMount() {
    this.loadPartner()
  },
  methods: {
    loadPartner() {
      this.loading = true
      PartnerApi.getPartner(this.partnerId).then(response => {
        this.partnerData = response
      }).finally(() => {
        this.loading = false
      })
    },
    onEdit() {
      this.$bvModal.show('partnerModal')
    },
    onEditSubmit(response) {
      this.partnerData.partner = response
      this.$bvModal.hide('partnerModal')
    },
  },
}
</script>
