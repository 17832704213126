<template>
  <partner-withdrawals-table-gql
    v-if="user"
    :columns="columns"
    table-title="Withdrawals"
  />
</template>
<script>

import PartnerWithdrawalsTableGql from '@/components/entity/partner-withdrawal-gql/PartnerWithdrawalsTableGql.vue'

export default {
  components: { PartnerWithdrawalsTableGql },
  props: { user: [Object, null] },
  data() {
    return {
      columns: [
        {
          name: 'partnerUser',
          label: 'User',
          filterable: {
            type: 'multientity', queryType: 'users', value: 'id', label: 'email', search: 'email', visible: false,
          },
          default: this.user.id,
        },
        {
          name: 'amount',
          label: 'Profit amount $',
          sortable: true,
        },
        {
          name: 'state',
          label: 'State',
          sortable: false,
          filterable: { type: 'multienum', enum: ['requested', 'investigation', 'investigation - contacted', 'investigation - declined', 'approved by support', 'completed'] },
        },
        {
          name: 'platform',
          label: 'Platform',
          sortable: false,
        },
        {
          name: 'confirmed_at',
          label: 'Confirmed at',
          sortable: true,
        },
        {
          name: 'createdAt',
          label: 'Created at',
          sortable: true,
        },
        {
          name: 'stateAction',
          label: 'State action',
          sortable: false,
        },
      ],
    }
  },
}
</script>
